.start-mapping {
  background: url('#{$images-background}strat-mapping-bg.svg') no-repeat center / 100%  auto;

    label {
      cursor: pointer;
    }

    p {

        label {
          color: $blue;
          text-decoration: underline;
        }
    }

    iframe {
      max-width: 100% !important;
    }

    input[type="file"] {
      position: absolute;
      visibility: hidden;
      opacity: 0;
    }

    &__box {
      border: 1px dashed #a8a7b1;
      font-size: rem(22px);
      background: rgba($gray-100, .7);
      
        @include media-breakpoint-up(sm) {
          min-height: 310px;
        }

        @include media-breakpoint-down(xs) {
          min-height: 190px;
        }

        &__hover {
          display: none;
          @include element-center();
          background: rgba($gray-100, .7);
        }

        &:hover {
          border-color: $black;

            .start-mapping__box__hover {
              display: flex;
            }
        }
    }

    &__infos {
      font-size: 15px;

        img {
          margin-right: 15px;
        }
    }
}