.page-header {
  background: no-repeat top center / cover;

    @include media-breakpoint-up(sm) {
      padding-top: rem(140px);
      font-size: rem(21px)
    }

    @include media-breakpoint-down(xs) {
      padding-top: 80px;

        // .btn {
        //   width: 240px;
        // }
    }

    a:not(.btn) {
      color: #2fb6ff;
      @extend .d-inline-block, .font-weight-bold;
    }
}