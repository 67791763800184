// stylelint-disable declaration-no-important, selector-list-comma-newline-after

//
// Headings
//
$heading-sizes: $h1-font-size, $h2-font-size, $h3-font-size, $h4-font-size, $h5-font-size, $h6-font-size;

h1, .h1 { font-size: $h1-font-size; }
h2, .h2 { font-size: $h2-font-size; }
h3, .h3 { font-size: $h3-font-size; }
h4, .h4 { font-size: $h4-font-size; }
h5, .h5 { font-size: $h5-font-size; }
h6, .h6 { font-size: $h6-font-size; }

@for $i from 1 to 6 {
  h#{$i}, .h#{$i}, .h#{$i}-rem {
    margin-bottom: $headings-margin-bottom;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;
  }

  h#{$i} {
    font-size: nth($heading-sizes, $i)
  }

  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      .h#{$i}#{$infix} {
        font-size: nth($heading-sizes, $i)
      }
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .font-size-base#{$infix} {
      font-size: $font-size-base !important;
    }

    .font-size-lg#{$infix} {
      font-size: $font-size-lg !important;
    }

    .font-size-sm#{$infix} {
      font-size: $font-size-sm !important;
    }
  }
}

.line-height-0 {
	line-height: 0 !important;
}

.line-height-base {
	line-height: $line-height-base !important;
}

.font-size-0 { font-size: 0 !important; }

@include media-breakpoint-only(md) {
  html { font-size: 11px; }
}

@include media-breakpoint-only(sm) {
  html { font-size: 9px; }
  body { font-size: 14px; }
  .font-size-base { font-size: 14px !important; }
  .font-size-sm { font-size: 13px !important; }
  .font-size-lg { font-size: 16px !important; }
}

@include media-breakpoint-down(xs) {
  html { font-size: 10px; }

  h1, .h1 { font-size: 40px; }
  h2, .h2 { font-size: 30px; }
  h3, .h3 { font-size: 26px; }
  h4, .h4 { font-size: 22px; }
  h5, .h5 { font-size: 18px; }
}