.get-started {
  background: url('#{$images-background}get-started-bg.jpg') no-repeat center / cover;
  
    @include media-breakpoint-up(sm) {
      font-size: rem(21px);
    }

    @include media-breakpoint-down(xs) {
      font-size: 20px;
    }

    .btn {

        @include media-breakpoint-up(sm) {
          width: 340px;
        }

        @include media-breakpoint-down(xs) {
          width: 240px;
        }
    }
}