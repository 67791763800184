@each $color, $value in $colors {
	@include text-emphasis-variant(".text-#{$color}", $value);
}

.font-weight-exbold { font-weight: $font-weight-exbold !important; }

.aligncenter {
	@extend .d-block, .mx-auto;
}

p,
.entry--content li {
	
		a {
			color: $blue;
			@extend .font-weight-bold;

				&:hover {
					color: $blue;
				}
		}
}

.entry--content {

		h1, h2, h3, h4, h5, h6,
		p, ul, ol, li, table, > div { 

				&:not(:last-child) {
					@extend .mb-30;
				}
		}

		ul {
			text-align: left;

				li {
					padding-left: 40px;
					background: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 12'%3e%3cpath fill='#19426e' d='M5.395,11.988 C5.297,11.988 5.201,11.949 5.134,11.882 L0.231,7.053 C0.081,6.906 -0.001,6.709 -0.001,6.501 C-0.001,6.293 0.081,6.098 0.231,5.951 L0.845,5.346 C0.997,5.196 1.200,5.114 1.415,5.114 C1.631,5.114 1.833,5.196 1.986,5.346 L5.395,8.706 L14.015,0.215 C14.167,0.067 14.369,-0.015 14.585,-0.015 C14.801,-0.015 15.003,0.067 15.156,0.215 L15.768,0.821 C15.918,0.968 16.001,1.164 16.001,1.373 C16.001,1.580 15.918,1.776 15.769,1.923 L5.659,11.880 C5.590,11.949 5.494,11.988 5.395,11.988 Z'/%3e%3c/svg%3e"), "#", "%23") no-repeat left 6px / 16px 12px;

						&:not(:last-child) {
							@extend .mb-20;
						}
				}

				ol, ul {
					@extend .mt-20;
				}
		}

		p {

				a {
					color: $blue;
					text-decoration: underline;
				}
		}

		&.post__content {

			h2 {
				@include media-breakpoint-up(sm) {
					font-size: rem(30px);
				}

				@include media-breakpoint-down(xs) {
					font-size: 20px;
				}
			}

			p,
			ol li,
			ul li {
				@include media-breakpoint-up(sm) {
					font-size: rem(20px);
				}

				@include media-breakpoint-down(xs) {
					font-size: 16px;
				}
			}

			p, ul, ol, h2 {

				&:not(:last-child) {
					@extend .mb-40;
				}
			}

			ul li strong {
				@extend .text-gunpowder;
			}
		}

		ol {

			ol, ul {
					@extend .mt-20;
				}
		}

		ol[start] {
			margin-left: 20px;
			list-style-type: decimal;
		}

		> ol:not([start]) {
			counter-reset: items;

			> li {
				position: relative;
				padding-left: 40px;
				@extend .font-weight-bold, .text-gunpowder;

				&:before,
				&:after {
					width: 25px;
					height: 25px;
					position: absolute;
					top: 0;
					left: 0;
				}

				&:before {
					counter-increment: items;
					font-size: 12px;
					line-height: 25px;
					content: counter(items);
					@extend .text-center, .text-white, .font-weight-bold;
					z-index: 2;
				}

				&:after {
					content: '';
					@include gradient-x(#513fff, #33aaff);
					@extend .rounded-circle;
					z-index: 1;
					box-shadow: 0 7px 12px rgba(#3a90ff,.35);
				}

				&:not(:last-child) {
					@extend .mb-30;
				}
			}
		}

		img {
			@extend .rounded;

			&.aligncenter {
				display: block;
				margin: 0 auto 40px;
			}
	
			&.alignleft {
				float: left;
	
				@include media-breakpoint-up(md) {
					margin-right: rem(40);
				}
	
				@include media-breakpoint-down(sm) {
					display: block;
					margin: 0 auto 40px;
					float: none !important;
				}
			}
	
			&.alignright {
				float: right;
	
				@include media-breakpoint-up(dm) {
					margin-left: rem(40);
				}
	
				@include media-breakpoint-down(sm) {
					display: block;
					margin: 0 auto 40px;
					float: none !important;
				}
			}
		}

		blockquote {
			border-radius: 9px;
			@extend .bg-gray-200, .text-center, .text-sm-left, .p-50, .mb-50;

				p {

					&:before {
						display: inline-block;
						content: '';
						width: 20px;
						height: 15px;
						left: 0;
						top: 0;
						margin-right: 15px;
						background: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 15'%3e%3cpath fill='rgb(46, 185, 255)' d='M18.838,11.938 C18.305,13.733 16.555,14.988 14.581,14.988 C14.575,14.988 14.568,14.987 14.562,14.987 C12.962,14.928 11.719,14.339 10.867,13.235 C9.344,11.262 9.525,8.128 10.143,6.043 C11.196,2.490 13.692,0.008 16.212,0.008 C16.369,0.008 16.527,0.018 16.682,0.038 C16.824,0.056 16.952,0.132 17.031,0.246 C17.110,0.359 17.133,0.499 17.094,0.630 L16.635,2.179 C16.582,2.358 16.423,2.490 16.228,2.518 C14.129,2.812 13.075,5.427 12.636,6.942 C13.091,6.756 13.678,6.605 14.382,6.605 C14.837,6.605 15.305,6.669 15.773,6.796 C16.915,7.106 17.859,7.806 18.432,8.767 C19.012,9.738 19.156,10.864 18.838,11.938 ZM4.884,14.987 C4.878,14.987 4.871,14.987 4.865,14.987 C3.265,14.928 2.022,14.339 1.170,13.235 C-0.353,11.262 -0.172,8.128 0.447,6.043 C1.500,2.490 3.995,0.008 6.515,0.008 C6.672,0.008 6.830,0.018 6.985,0.038 C7.127,0.056 7.255,0.132 7.334,0.246 C7.413,0.359 7.436,0.499 7.397,0.630 L6.938,2.179 C6.885,2.358 6.726,2.490 6.532,2.518 C4.432,2.812 3.378,5.427 2.939,6.942 C3.395,6.756 3.981,6.605 4.685,6.605 C5.140,6.605 5.608,6.669 6.077,6.796 C7.218,7.106 8.162,7.806 8.736,8.767 C9.315,9.738 9.459,10.864 9.141,11.938 C8.609,13.733 6.858,14.987 4.884,14.987 Z'/%3e%3c/svg%3e"), "#", "%23") no-repeat left center;
					}

					&:after {
						display: inline-block;
						content: '';
						width: 20px;
						height: 15px;
						left: 0;
						top: 0;
						margin-left: 15px;
						background: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 15'%3e%3cpath fill='rgb(46, 185, 255)' d='M18.553,8.957 C17.500,12.510 15.004,14.992 12.484,14.992 C12.328,14.992 12.170,14.982 12.015,14.962 C11.872,14.943 11.745,14.868 11.666,14.754 C11.587,14.640 11.564,14.500 11.603,14.369 L12.062,12.821 C12.115,12.642 12.274,12.509 12.468,12.482 C14.568,12.188 15.622,9.573 16.060,8.058 C15.605,8.243 15.018,8.395 14.315,8.395 C13.860,8.395 13.391,8.330 12.923,8.203 C11.782,7.893 10.837,7.193 10.264,6.233 C9.685,5.261 9.541,4.135 9.859,3.062 C10.391,1.266 12.142,0.012 14.116,0.012 C14.122,0.012 14.128,0.012 14.135,0.012 C15.735,0.071 16.978,0.661 17.830,1.765 C19.353,3.738 19.172,6.872 18.553,8.957 ZM2.788,14.992 C2.631,14.992 2.473,14.982 2.318,14.962 C2.176,14.943 2.048,14.868 1.969,14.754 C1.890,14.640 1.867,14.500 1.906,14.369 L2.365,12.821 C2.418,12.642 2.577,12.509 2.772,12.482 C4.871,12.188 5.925,9.573 6.364,8.058 C5.909,8.243 5.322,8.395 4.618,8.395 C4.163,8.395 3.695,8.330 3.227,8.203 C2.085,7.893 1.141,7.193 0.568,6.233 C-0.012,5.261 -0.156,4.135 0.162,3.062 C0.694,1.266 2.445,0.012 4.419,0.012 C4.425,0.012 4.432,0.012 4.438,0.012 C6.038,0.071 7.281,0.661 8.133,1.764 C9.656,3.738 9.475,6.872 8.857,8.957 C7.803,12.510 5.308,14.992 2.788,14.992 Z'/%3e%3c/svg%3e"), "#", "%23") no-repeat left center;
					}
				}

				h3 {
					font-size: 19px;
					@extend .font-weight-bold;
				}

				h4 {
					font-size: 18px;
					@extend .text-cadetblue, .font-weight-normal;
				}
		}

		strong {
			color: $gunpowder;
		}

		table {
			border: 1px solid $gray-300;

				tr {

						&:nth-child(even) {
							background-color: $gray-100;
						}
				}
				
				td {
					@extend .p-10;
				}
		}

		.step {
			@extend .d-inline-block, .py-8, .px-20, .text-center, .text-white, .font-weight-bold;
			min-width: 180px;
			@include gradient-x(#5544ff, #33aaff);
			@extend .rounded-pill;
		}
}

.bullet-circle {

		ul {

				li {
					padding-left: 40px;
					background: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21 20'%3e%3cdefs%3e%3clinearGradient id='PSgrad_0' x1='50%' x2='0%' y1='86.603%' y2='0%'%3e%3cstop offset='0%' stop-color='rgb(44,193,255)' stop-opacity='1' /%3e%3cstop offset='100%' stop-color='rgb(85,51,255)' stop-opacity='1'/%3e%3c/linearGradient%3e%3c/defs%3e%3cpath fill='url(#PSgrad_0)' d='M10.622,0.124 C5.176,0.124 0.747,4.554 0.747,9.999 C0.747,15.444 5.176,19.875 10.622,19.875 C16.067,19.875 20.497,15.444 20.497,9.999 C20.497,4.554 16.067,0.124 10.622,0.124 ZM15.627,7.906 L10.278,13.255 C10.117,13.416 9.906,13.497 9.696,13.497 C9.485,13.497 9.275,13.416 9.114,13.255 L6.440,10.581 C6.118,10.259 6.118,9.739 6.440,9.417 C6.761,9.095 7.281,9.095 7.603,9.417 L9.696,11.510 L14.463,6.743 C14.785,6.421 15.305,6.421 15.627,6.743 C15.948,7.065 15.948,7.584 15.627,7.906 Z'/%3e%3c/svg%3e"), "#", "%23") no-repeat left top / 21px 20px;
				}
		}
}

.bullet-arrow {

		ul {

				li {
					padding-left: 40px;
					background: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23 23'%3e%3cdefs%3e%3clinearGradient id='PSgrad_1' x1='50%' x2='0%' y1='86.603%' y2='0%'%3e%3cstop offset='0%' stop-color='rgb(44,193,255)' stop-opacity='1' /%3e%3cstop offset='100%' stop-color='rgb(85,51,255)' stop-opacity='1'/%3e%3c/linearGradient%3e%3c/defs%3e%3cpath fill='url(#PSgrad_1)' d='M11.496,22.998 C5.154,22.998 -0.004,17.840 -0.004,11.498 C-0.004,5.156 5.154,-0.002 11.496,-0.002 C17.837,-0.002 22.996,5.156 22.996,11.498 C22.996,17.840 17.837,22.998 11.496,22.998 ZM15.779,10.986 L13.116,8.202 C12.984,8.063 12.806,7.986 12.618,7.986 C12.428,7.986 12.251,8.063 12.118,8.204 C11.852,8.485 11.853,8.942 12.121,9.223 L13.602,10.772 L8.689,10.772 C8.303,10.772 7.989,11.097 7.989,11.498 C7.989,11.897 8.303,12.223 8.689,12.223 L13.602,12.223 L12.121,13.772 C11.852,14.053 11.851,14.510 12.118,14.792 C12.251,14.932 12.429,15.009 12.618,15.009 C12.806,15.009 12.983,14.933 13.116,14.794 L15.779,12.007 C16.047,11.726 16.047,11.269 15.779,10.986 Z'/%3e%3c/svg%3e"), "#", "%23") no-repeat left top / 23px 23px;
				}
		}
}