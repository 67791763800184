//
// Base styles
//

.btn {
	display: inline-block;
	font-family: $btn-font-family;
	font-weight: $btn-font-weight;
	color: $white;
	text-align: center;
	vertical-align: middle;
	user-select: none;
  background: transparent;
	border: $btn-border-width solid transparent;
	@include button-size($btn-height, $btn-padding-x, $btn-font-size, 1, $btn-border-radius);
	@extend .text-decoration-none;

		@include media-breakpoint-down(sm) {

				&:not(.btn--sm) {
					height: 45px;
					font-size: 15px;
					padding: 0 20px;
				}
		}

		@include media-breakpoint-only(sm) {
			padding: 0 20px;
		}

		@include media-breakpoint-down(xs) {
			
				&:not(.btn--sm) {
					min-width: 240px;
				}
		}

		@at-root :not(button)#{&} {
			@extend .d-inline-flex;
			@extend .align-items-center;
			@extend .justify-content-center;
			cursor: pointer;
		}

		&--sm {
			@include button-size($btn-height-sm, $btn-padding-x-sm, $btn-font-size-sm, 1, $btn-border-radius);
		}

		&__menu {
			@include size($btn-height-sm);
		}

		&__state {

				&__default {

						.btn__state[aria-expanded="true"] &,
						.btn__state.active:not([aria-expanded]) & {
						// input:checked ~ .btn__state & {
							@extend .d-none;
						}
				}

				&__active {
						
						.btn__state[aria-expanded="false"] &,
						.btn__state:not(.active):not([aria-expanded]) & {
							@extend .d-none;
						}
				}
		}
}

//
// Alternate buttons
//
@each $color, $value in $btn-colors {
	$text-color: map-get($value, color);
	$background: map-get($value, background);
	$border: map-get($value, border);
	$box-shadow: map-get($value, box-shadow);
	$hover-color: map-get($value, hover-color);
	$hover-background: map-get($value, hover-background);
	$hover-border: map-get($value, hover-border);

		.btn--#{$color} {
			@include button-variant(
				$text-color,
				$background,
				$border,
				$box-shadow,
				$hover-color,
				$hover-background,
				$hover-border
			);
		}
}

@each $color, $value in $btn-outline-colors {
	$text-color: map-get($value, color);
	$border: map-get($value, border);
	$box-shadow: map-get($value, box-shadow);
	$hover-color :map-get($value, hover-color);
	$hover-background: map-get($value, hover-background);
  $hover-border: map-get($value, hover-border);

		.btn--outline-#{$color} {
			@include button-outline-variant(
				$text-color,
				$border,
				$box-shadow,
				$hover-color,
				$hover-background,
        $hover-border
			);
		}
}
