.f {
  
    &__header {
      background: url('#{$images-background}blue-map-bg.jpg') no-repeat top center / cover;

        @include media-breakpoint-up(sm) {
          padding-top: rem(120px);
          font-size: rem(21px)
        }

        @include media-breakpoint-down(xs) {
          padding-top: 80px;

            figure {
              padding-bottom: percentage(185 / 345);
              height: 0;
              position: relative;

                img {
                  @include element-center();
                  width: auto;
                  max-height: 100%;
                }
            }
        }
    }

    &__breadcrumbs {
      font-size: 16px;

        img {
          max-width: 13px;
        }

        button {
          @extend .font-weight-bold;
          color: inherit;

            @include media-breakpoint-up(sm) {
              
                &:hover {
                  color: $blue;
                }
            }
        }

        .swiper-slide {
          width: auto;
          @extend .px-25, .py-10;
        }

        &__next,
        &__prev {
          @include element-center(true, 0, false, 0, false);
          width: 100px;
          z-index: 2;
        }

        &__next {
          right: 0;
        }

        &__prev {
          left: 0;
        }

        &--white {
          color: $white;

            .swiper-container {
              background: rgba($white, .12);
            }

            .swiper-slide {

                &:not(:first-child) {
                  @extend .position-relative;
    
                    &:before {
                      content: '';
                      @include element-center(true, 0, false, 0, -7px);
                      width: 14px;
                    }
    
                    &:not(.active):before {
                      background: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 11'%3e%3cpath fill='#{$white}' d='M8.829,0.075 L7.533,1.501 C7.444,1.598 7.444,1.753 7.533,1.850 L9.729,4.249 L0.233,4.249 C0.104,4.249 -0.000,4.361 -0.000,4.500 L-0.000,6.500 C-0.000,6.638 0.104,6.750 0.233,6.750 L9.729,6.750 L7.533,9.149 C7.445,9.246 7.444,9.401 7.533,9.498 L8.829,10.924 C8.872,10.972 8.933,11.000 8.996,11.000 C9.059,11.000 9.119,10.972 9.163,10.924 L13.934,5.674 C13.978,5.625 14.000,5.562 14.000,5.499 C14.000,5.437 13.978,5.374 13.934,5.325 L9.163,0.075 C9.119,0.027 9.059,-0.001 8.996,-0.001 C8.933,-0.001 8.872,0.027 8.829,0.075 Z'/%3e%3c/svg%3e"), "#", "%23") no-repeat right center / 14px 11px;
                    }
    
                    &.active:before {
                      background: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 11'%3e%3cpath fill='#c6d6fc' d='M8.829,0.075 L7.533,1.501 C7.444,1.598 7.444,1.753 7.533,1.850 L9.729,4.249 L0.233,4.249 C0.104,4.249 -0.000,4.361 -0.000,4.500 L-0.000,6.500 C-0.000,6.638 0.104,6.750 0.233,6.750 L9.729,6.750 L7.533,9.149 C7.445,9.246 7.444,9.401 7.533,9.498 L8.829,10.924 C8.872,10.972 8.933,11.000 8.996,11.000 C9.059,11.000 9.119,10.972 9.163,10.924 L13.934,5.674 C13.978,5.625 14.000,5.562 14.000,5.499 C14.000,5.437 13.978,5.374 13.934,5.325 L9.163,0.075 C9.119,0.027 9.059,-0.001 8.996,-0.001 C8.933,-0.001 8.872,0.027 8.829,0.075 Z'/%3e%3c/svg%3e"), "#", "%23") no-repeat right center / 14px 11px;
                    }
                }
            }

            .f__breadcrumbs__next {
              @include gradient-x(rgba($white, 0), #397af8)
            }

            .f__breadcrumbs__prev {
              @include gradient-x(#397af8, rgba($white, 0))
            }

            li.active {
              color: #c6d6fc;
            }
        }

        &--dark {
          color: $gunpowder;

            .swiper-container {
              background: rgba($gunpowder, .10);
            }

            .swiper-slide {

                &:not(:first-child) {
                  @extend .position-relative;
    
                    &:before {
                      content: '';
                      @include element-center(true, 0, false, 0, -7px);
                      width: 14px;
                    }
    
                    &:not(.active):before {
                      background: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 11'%3e%3cpath fill='#{$gunpowder}' d='M8.829,0.075 L7.533,1.501 C7.444,1.598 7.444,1.753 7.533,1.850 L9.729,4.249 L0.233,4.249 C0.104,4.249 -0.000,4.361 -0.000,4.500 L-0.000,6.500 C-0.000,6.638 0.104,6.750 0.233,6.750 L9.729,6.750 L7.533,9.149 C7.445,9.246 7.444,9.401 7.533,9.498 L8.829,10.924 C8.872,10.972 8.933,11.000 8.996,11.000 C9.059,11.000 9.119,10.972 9.163,10.924 L13.934,5.674 C13.978,5.625 14.000,5.562 14.000,5.499 C14.000,5.437 13.978,5.374 13.934,5.325 L9.163,0.075 C9.119,0.027 9.059,-0.001 8.996,-0.001 C8.933,-0.001 8.872,0.027 8.829,0.075 Z'/%3e%3c/svg%3e"), "#", "%23") no-repeat right center / 14px 11px;
                    }
    
                    &.active:before {
                      background: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 11'%3e%3cpath fill='#{$gray-800}' d='M8.829,0.075 L7.533,1.501 C7.444,1.598 7.444,1.753 7.533,1.850 L9.729,4.249 L0.233,4.249 C0.104,4.249 -0.000,4.361 -0.000,4.500 L-0.000,6.500 C-0.000,6.638 0.104,6.750 0.233,6.750 L9.729,6.750 L7.533,9.149 C7.445,9.246 7.444,9.401 7.533,9.498 L8.829,10.924 C8.872,10.972 8.933,11.000 8.996,11.000 C9.059,11.000 9.119,10.972 9.163,10.924 L13.934,5.674 C13.978,5.625 14.000,5.562 14.000,5.499 C14.000,5.437 13.978,5.374 13.934,5.325 L9.163,0.075 C9.119,0.027 9.059,-0.001 8.996,-0.001 C8.933,-0.001 8.872,0.027 8.829,0.075 Z'/%3e%3c/svg%3e"), "#", "%23") no-repeat right center / 14px 11px;
                    }
                }
            }

            .f__breadcrumbs__next {
              @include gradient-x(rgba($white, 0), rgba($white, .8))
            }

            .f__breadcrumbs__prev {
              @include gradient-x(rgba($white, .8), rgba($white, 0))
            }

            li.active {
              color: $gray-800;
            }
        }
    }

    &__info {
      background: url("#{$images-background}group-bg-2.svg") no-repeat center / 100% auto;

        @include media-breakpoint-up(sm) {
          font-size: rem(20px);
        }

        &__icon {
          @include size(100px);
          background: #e2f2fc;

            &:after {
              content: '';
              @include element-center(true, false, -30px, 0, false);
              @include size(52px);
              @extend .rounded-circle;
              background: url('#{$images-icons}f-ifon-pin.svg') no-repeat;
            }

            img {
              max-height: 100%;
              width: auto;
            }
        }
    }

    &__tabs {
      box-shadow: 0 10px 44px rgba(#1d1c35, .25);

        &__btn {
          width: auto;
          cursor: pointer;
          user-select: none;
          box-shadow: 1px 0 0 0 #c3c3c3, -1px 0 0 0 #c3c3c3;

            @include media-breakpoint-up(sm) {
              height: 70px;
              font-size: 17px;
            }

            @include media-breakpoint-down(xs) {
              height: 60px;
              font-size: 14px;
            }

            img {
              max-width: 23px;
              max-height: 23px;
              width: auto;
            }
          
            &:not(.active) {
                
                img {
                  filter: brightness(0);
                }
            }

            &.active {
              color: $neon;
              font-weight: $font-weight-bold;
              z-index: 2;
              box-shadow: 1px 0 0 0 $neon, -1px 0 0 0 $neon;
              background-color: $white;
            }
        }

        &__next,
        &__prev {
          @include element-center(true, 0, false, 0, false);
          @extend .z-index-3;
          
            @include media-breakpoint-up(sm) {
              width: 100px;
            }

            @include media-breakpoint-down(xs) {
              width: 75px;

                svg {
                  width: 20px;
                }
            }
        }

        &__next {
          right: 0;
          @include gradient-x(rgba($white, 0), $white, 0, 30%);
        }

        &__prev {
          left: 0;
          @include gradient-x($white, rgba($white, 0), 70%, 100%);
        }

        .tab-content {
          box-shadow: 0px -3px 20px 0px rgba(0, 0, 0, 0.09);
        }
    }

    &__steps {
      background: url('#{$images-background}group-bg-3.svg') no-repeat center / 100% auto;
      
        @include media-breakpoint-up(md) {
          font-size: rem(20px);
        }

        &__box {
          box-shadow: 0 10px 26px rgba($black, .11);

            &:before {
              content: '';
              @include element-center(true, 0, false, false, -18px);
              @include size(23px, 121px);
              @include border-top-radius($border-radius-lg);
              @include border-bottom-radius($border-radius-lg);
              @extend .bg-blue;
            }

            @include media-breakpoint-up(md) {
              font-size: rem(21px);
            }
        }

        &__num {
          font-size: 23px;
        }
    }
}