.divider {
  
    &:before,
    &:after {
      content: '';
      @include element-center(true, 0, false, 0, false);
      @include size(rem(196px), 1px);
      background-color: rgba($gunpowder, .16);
    }

    &:before {
      right: calc(50% + 2.81rem);
    }

    &:after {
      left: calc(50% + 2.81rem);
    }

    &--lg {


        &:before,
        &:after {
          
          @each $breakpoint, $container-max-width in $container-max-widths {
            @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
              width: calc(((#{$container-max-width} / 2) - (#{$grid-gutter-width} / 2)) - 2.81rem);
            }
          }
        }
    }
}