.widget-popular {
  
  .title {
    color: $gunpowder;
    @include media-breakpoint-up(lg) {
      font-size: 15px;
    }
    @include media-breakpoint-down(md) {
      font-size: 14px;
    }

    &:hover {
      color: $neon;
    }
  }

  .thumb-popular {

    img {
      max-width: 58px;
      @extend .rounded;
    }
  }

  li {
     &:not(:last-child) {
      @extend .mb-15;
     }
    

    a {

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.widget-trail {
  @include media-breakpoint-down(xs) {
    padding: 40px;
  }

  &__subtitle {
    font-size: rem(20px);

    @include media-breakpoint-down(md) {

      br {
        display: none;;
      }
    }
  }
}

.widget-learn-more {

    .btn {
      height: 34px;
      font-size: 14px;
    }
}