.side-menu {
  z-index: $zindex-fixed + 2;
  transition: $default-transition;
  right: 0;
  left: auto;
  transform: translatex(100%);

    .side-menu-opened & {
      transform: translateX(0);
    }

    @include media-breakpoint-up(sm) {
      width: 440px;
    }

    // @include media-breakpoint-up(lg) {
    //   transform: translatex(100%);

    //     .side-menu-opened & {
    //       transform: translateX(0);
    //     }

    //     &:after {
    //       content: '';
    //       position: absolute;
    //       top: 0;
    //       left: 100%;
    //       bottom: 0;
    //       width: 9999px;
    //       background-color: inherit;
    //     }
    // }

    // @include media-breakpoint-down(md) {
    //   left: auto;
    //   transform: translatex(100%);

    //     .side-menu-opened & {
    //       transform: translateX(0);
    //     }
    // }

    @include media-breakpoint-down(xs) {
      width: 100%;
    }

    &__scroll {
      max-height: 100vh;
    }

    .btn {
      border-width: 2px;
    }
}

.menu-aside {

    div {
      line-height: 1;
    }

    a {
      @extend .align-items-center, .position-relative, .text-white;

        span {
          @extend .d-flex, .mr-10, .align-items-center, .justify-content-center;
          @include size(rem(60px), 30px);

            img {
              width: auto;
              max-height: 100%;
            }
        }
    }

    > li {
      @extend .font-weight-bold;
      border-bottom: 1px solid rgba(#fdfffd, .11);

        > div {

            @include media-breakpoint-up(sm) {

                &:hover {
                  background-color: lighten($black, 5%);
                }
            }
        }

        @include media-breakpoint-up(sm) {
          font-size: 19px;
        }

        &:not(.menu-item-has-children) {

            > div a {
              @extend  .py-20, .px-30;
              @extend .d-flex;
            }
        }

        &.menu-item-has-children {
          cursor: pointer;

            > div {
              @extend .position-relative, .py-20, .px-30;

                a {
                  @extend .d-inline-flex;
                }

                &:before {
                  content: '';
                  @include element-center(true, 0, 0, false, false);
                  width: 50px;
                  z-index: 5;
                  background: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3e%3cpath fill='#{$blue}' d='M8.993,6.015 L5.994,6.015 L5.994,9.015 C5.994,9.567 5.546,10.015 4.993,10.015 C4.441,10.015 3.993,9.567 3.993,9.015 L3.993,6.015 L0.993,6.015 C0.441,6.015 -0.007,5.567 -0.007,5.015 C-0.007,4.463 0.441,4.015 0.993,4.015 L3.993,4.015 L3.993,1.015 C3.993,0.463 4.441,0.015 4.993,0.015 C5.546,0.015 5.994,0.463 5.994,1.015 L5.994,4.015 L8.993,4.015 C9.546,4.015 9.993,4.463 9.993,5.015 C9.993,5.567 9.546,6.015 8.993,6.015 Z'/%3e%3c/svg%3e"), "#", "%23") no-repeat center / 10px 10px;
    
                    @include media-breakpoint-up(lg) {
                      height: 71px;
                    }
    
                    @include media-breakpoint-only(md) {
                      height: 58px;
                    }
    
                    @include media-breakpoint-only(sm) {
                      height: 53px;
                    }
    
                    @include media-breakpoint-down(xs) {
                      height: 56px;
                    }
                }
            }

            &.opened {

                > div {
                  border-bottom-color: $blue;

                    &:before {
                      background: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 2'%3e%3cpath fill='#{$blue}' d='M9.993,1.015 C9.993,1.567 9.546,2.015 8.993,2.015 L0.993,2.015 C0.441,2.015 -0.007,1.567 -0.007,1.015 C-0.007,0.463 0.441,0.015 0.993,0.015 L8.993,0.015 C9.546,0.015 9.993,0.463 9.993,1.015 Z'/%3e%3c/svg%3e"), "#", "%23") no-repeat center / 10px 2px;
                    }
                }

                > .submenu,
                > .sub-menu {
                  display: block;
                }
            }
        }
    }

    .submenu,
    .sub-menu {
      display: none;
      @extend .px-sm-50, .px-15, .py-10;
      background-color: #222528;

        a {
          @extend .d-flex, .pl-15, .pr-50, .py-10, .rounded;

            &:before,
            &:after {
              content: '';
              @include element-center(true, 0, 20px, 0, false);
              @include size(6px, 1px);
              background-color: $blue;
            }

            &:before {
              transform: rotate(45deg) translate(0, -3px);
            }

            &:after {
              transform: rotate(-45deg) translate(0, 3px);
            }

            span {
              @extend .mr-10;
              opacity: .5;
              transition: $default-transition;
            }

            &:hover {
              @extend .text-white, .bg-blue;

                &:before,
                &:after {
                  background-color: $white;
                }

                span {
                  opacity: 1;
                }
            }
        }
    }
}

.menu-aside-footer {

    a {
      color: $blue;

        &:hover {
          color: $blue;
        }
    }
}


.side-menu-opened {
  @extend .overflow-hidden;

    &:before {
      content: '';
      @extend .fixed-top;
      bottom: 0;
      z-index: $zindex-fixed + 1;
      background-color: rgba($black, .6);
    }
}