
// Color system
$white    : #fff;
$gray-100 : #F9F9F9;
$gray-200 : #f3f8fb;
$gray-300 : #bbc3d9;
$gray-400 : #D5D6D7;
$gray-500 : #97979a;
$gray-600 : #7a88ad;
$gray-700 : #78838a;
$gray-800 : #6e6f78;
$gray-900 : #2a2e31;
$black    : #000;

// Theme Colors
$green     : #58df5f;
$bayoux    : #536d85;
$gunpowder : #3F3C51;
$cadetblue : #AABAC4;
$blue      : #2eb9ff;
$red       : #f44e4e;
$orange    : #f87d4c;
$java      : #26c9b0;
$dodger    : #2794f2;
$orchid    : #da47da;
$neon      : #4f49ff;

// Typography

// Buttons
$btn-colors: (
	"green": (
		color            : $white,
		background       : $green,
		border           : $green,
		hover-color      : $white,
		hover-background : darken($green, 10%),
		hover-border     : darken($green, 10%),
		box-shadow       : 0 8px 27px rgba($green, .28)
  )
);

$btn-outline-colors: (
	"white": (
		color            : $white,
		border           : $white,
		hover-color      : $black,
		hover-background : $white,
		hover-border     : $white,
		box-shadow       : none
  ),
  "blue": (
		color            : $blue,
		border           : $blue,
		hover-color      : $white,
		hover-background : $blue,
		hover-border     : $blue,
		box-shadow       : (0 0 0 4px rgba($blue, .15), 0 rem(17px) rem(44px) rgba($blue, .13))
  )
);