.breadcrumb {
  box-shadow: 0 2px 26px rgba(#070757, .09);

  ul {
    overflow: auto;
    white-space: nowrap;

    li {
      @extend .d-inline-block, .align-middle, .position-relative;
  
      &:not(:first-child) {
        @extend .pl-15;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 4px;
          height: 7px;
          background: url('#{$images-icons}breadcrumb-item.svg') center center no-repeat;
          margin: auto;
        }
      }

      &:not(:last-child) {
        @extend .pr-15;
      }

      &:last-child {
        @extend .text-cadetblue;
      }
    }

    a {
      color: $gunpowder;
      @extend .d-inline-block, .align-middle, .font-weight-bold;
    }
  }

  .home {
    width: 13px;
    height: 18px;
    background: url('#{$images-icons}logo-breadcrumb.svg') center center no-repeat;
    @extend .d-inline-block;
  }
}