.contacts {
  background: url('#{$images-background}contacts-bg.jpg') no-repeat center / cover;

    @include media-breakpoint-up(sm) {
      background-position: center rem(120px);
    }

    &__text {
      
        @include media-breakpoint-up(md) {
          font-size: rem(22px);
        }

        @include media-breakpoint-up(sm) {
          padding-top: rem(200px);
        }

        @include media-breakpoint-down(xs) {
          font-size: 20px;
        }
    }

    &__form {
      background: url('#{$images-background}create-account-box.svg') top right no-repeat;
      box-shadow: 0 10px 50px rgba(0,0,0,.12);

        .btn {
          width: 270px;
        }
    }

    ul  {

        li {
          @extend .pl-sm-50, .pl-70;
          background: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23 23'%3e%3cdefs%3e%3clinearGradient id='check-arrow' x1='50%' x2='0%' y1='86.603%' y2='0%'%3e%3cstop offset='0%' stop-color='rgb(44,193,255)' stop-opacity='1' /%3e%3cstop offset='100%' stop-color='rgb(85,51,255)' stop-opacity='1' /%3e%3c/linearGradient%3e%3c/defs%3e%3cpath fill='url(#check-arrow)' d='M11.496,22.997 C5.155,22.997 -0.004,17.840 -0.004,11.498 C-0.004,5.156 5.155,-0.003 11.496,-0.003 C17.837,-0.003 22.996,5.156 22.996,11.498 C22.996,17.840 17.837,22.997 11.496,22.997 ZM15.779,10.986 L13.116,8.201 C12.984,8.063 12.806,7.986 12.618,7.986 C12.428,7.986 12.251,8.063 12.118,8.204 C11.852,8.485 11.853,8.942 12.121,9.223 L13.602,10.771 L8.689,10.771 C8.303,10.771 7.989,11.098 7.989,11.497 C7.989,11.897 8.303,12.223 8.689,12.223 L13.602,12.223 L12.121,13.772 C11.852,14.053 11.851,14.510 12.118,14.792 C12.251,14.932 12.429,15.008 12.618,15.008 C12.806,15.008 12.983,14.933 13.116,14.793 L15.779,12.007 C16.047,11.726 16.047,11.269 15.779,10.986 Z'/%3e%3c/svg%3e"), "#", "%23") no-repeat;

            @include media-breakpoint-up(md) {
              background-size: rem(23px);
              background-position: 0 rem(4px);
            }

            @include media-breakpoint-only(sm) {
              background-size: 18px;
              background-position: 0 1px;
            }

            @include media-breakpoint-down(xs) {
              background-size: 23px;
              background-position: 0 4px;
            }

            &:not(:last-child) {
              @extend .mb-25;
            }
        }
    }
}
