.more-topics {

  &__top {

    &:before,
    &:after {
      content: '';
      @extend .position-absolute;
      height: 1px;
      width: 45%;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: rgba($gunpowder, .16);
      z-index: 1;
    }

    &:after {
      right: 0;
    }

    &:before {
      left: 0;
    }
  }

  &__btn {
    background: url('#{$images-icons}blue-logo.svg') 92% 50% no-repeat;
    text-transform: capitalize;
  }
}