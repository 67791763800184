.post {

  &__header {
    @include media-breakpoint-up(sm) {
      padding: rem(160px) 0 rem(100px);
    }

    @include media-breakpoint-down(xs) {
      padding: 70px 0 20px;

      h1 {
        font-size: 34px;
      }
    }
  }

  &__img {
    @include media-breakpoint-up(sm) {
      box-shadow: 0 48px 31px rgba(#070757, .25);
    }

    @include media-breakpoint-down(xs) {
      box-shadow: 0 18px 15px rgba(#070757, .25);
    }
  }

  &__category {
    font-size: 0;
    
    li {
      @extend .d-inline-block;

      &:not(:last-child) {
        @extend .pr-10;
      }
    }
  }

  &__category-item {
    font-size: 11px;
    color: #009cff;
    background-color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
    @extend .px-15, .font-weight-bold;

    &:hover {
      text-decoration: none;
      color: $gunpowder;
    }
  }

  &__title {
    font-size: rem(58px);
    line-height: (68/58);
  }

  &__meta {

    li {
      line-height: 1;

      &:not(:last-child) {
        border-right: 1px solid $cadetblue;
      }
    }
  }
}