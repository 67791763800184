.pricing {

    &--table {
      background: url('#{$images-background}pricing-table-bg.svg') no-repeat center / 100% auto;
    }

    &__btn {
      @include size(130px, $btn-height-sm);
      line-height: 1;
      font-size: 13px;
      color: $black;
      border: 1px solid $black;
      background-color: $white;

        &:hover {
          color: $white;
          background-color: $black;
        }

        &.active {
          color: $white;
          background: $blue;
          border-color: transparent;
          box-shadow: 0 0 0 3px rgba($blue, .15);
        }
    
        &--check {
          width: 160px;
          padding: 0 6px 0 30px;

            &:before {
              content: '';
              @include size(24px);
              @include element-center(true, 0, false, 0, 6px);
              @extend .rounded-circle, .d-inline-block;
              border: 1px solid #c8c8c8;
              transition: border-color 250ms ease-in-out;
            }

            &:hover:before {
              border-color: $black;
            }

            &.active {

                &:before {
                  border-color: transparent;
                  background: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 8'%3e%3cpath fill='#{$blue}' d='M3.372,7.991 C3.311,7.991 3.251,7.966 3.208,7.921 L0.144,4.701 C0.051,4.604 -0.001,4.472 -0.001,4.333 C-0.001,4.195 0.051,4.065 0.144,3.967 L0.528,3.564 C0.623,3.463 0.750,3.409 0.884,3.409 C1.019,3.409 1.146,3.463 1.241,3.564 L3.372,5.803 L8.759,0.143 C8.854,0.044 8.981,-0.010 9.116,-0.010 C9.250,-0.010 9.377,0.044 9.472,0.143 L9.855,0.547 C9.949,0.645 10.001,0.776 10.001,0.915 C10.001,1.053 9.949,1.183 9.856,1.282 L3.537,7.920 C3.494,7.966 3.434,7.991 3.372,7.991 Z'/%3e%3c/svg%3e"), "#", "%23") no-repeat center / 10px 8px;
                  @extend .bg-white;
                }
            }
        }
    }
}

.table-scroll {

    @include media-breakpoint-down(xs) {
      overflow: auto;
    }
}

.ptable {
  color: #444444;
  table-layout: fixed;

    @include media-breakpoint-down(md) {
      font-size: 14px;
    }

    td {
      border-spacing: 0;
      height: 1px;
      background: $gray-200;
      
        @include media-breakpoint-up(sm) {
          padding: 20px rem(20px);
        }

        @include media-breakpoint-down(xs) {
          padding: 10px 15px;
        }

        &:first-child {
          text-align: left;

            @include media-breakpoint-up(sm) {
              width: rem(240px);
            }

            @include media-breakpoint-down(xs) {
              position: sticky;
              position: -webkit-sticky;
              width: 152px;
              left: 0;
              z-index: 5;
            }
        }

        &:not(:first-child) {
          width: 115px;

            @include media-breakpoint-down(xs) {
              width: 145px;
            }
        }

        &:nth-child(even) {
          background: $white;
          position: relative;
			border-left: 1px solid;
			border-right: 1px solid;

            &:before {
              content: '';
              position: absolute;
              top: 0;
              right: 100%;
              bottom: 0;
              width: 7px;
              @include gradient-x(rgba($black, 0), rgba($black, .04));
            }

            &:after {
              content: '';
              position: absolute;
              top: 0;
              left: 100%;
              bottom: 0;
              width: 7px;
              @include gradient-x(rgba($black, .04), rgba($black, 0));
            }
        }
    }

    tr {

        &:nth-child(even):not(:last-child) {

            td {

                &:first-child {
                  @include border-left-radius(12px);
                }

                &:nth-child(odd) {
                  background: #e8f1f6;
                }

                &:nth-child(even) {
                  background: #f9fcfd;
                }
            }
        }

        &:not(:last-child) td:nth-child(even) {
          z-index: 2;
        }

        &:first-child {

            td {

                &:nth-child(even) {
				  border-top: 1px solid;
                  //@include border-top-radius(12px);
                  box-shadow: 0 -0 15px rgba($black, .15);

                    &:before,
                    &:after {
                      display: none
                    }
                }
            }
        }

        &:last-child {

          td {

              &:nth-child(even) {
				 border-bottom: 1px solid;
                //@include border-bottom-radius(12px);
                box-shadow: 0 0 15px rgba($black, .15);

                  &:before,
                  &:after {
                    display: none
                  }
              }
          }
      }
    }

    &__icon {

        @include media-breakpoint-up(md) {
          height: 38px;
        }

        @include media-breakpoint-only(sm) {
          height: 23px;
        }

        img {
          width: auto;
          max-height: 100%;
        }
    }

    &__title {
      
        @include media-breakpoint-up(md) {
          font-size: 21px;
        }

        @include media-breakpoint-only(sm) {
          font-size: 15px;
        }

        @include media-breakpoint-down(xs) {
          font-size: 18px;
        }
    }

    &__price {
      line-height: 1;

        @include media-breakpoint-up(md) {
          font-size: 14px;
        }

        @include media-breakpoint-only(sm) {
          font-size: 11px;
        }

        @include media-breakpoint-down(xs) {
          font-size: 15px;
        }

        span {
            @include media-breakpoint-up(md) {
              font-size: 21px;
            }
    
            @include media-breakpoint-only(sm) {
              font-size: 15px;
            }

            @include media-breakpoint-down(xs) {
              font-size: 22px;
            }
        }
    }

    &__billed {
      
        @include media-breakpoint-up(lg) {
          font-size: 14px;
        }

        @include media-breakpoint-between(sm, md) {
          font-size: 12px;
        }
    }

    .btn--green {

        @include media-breakpoint-only(sm) {
          font-size: 10px;
        }

        @include media-breakpoint-down(xs) {
          font-size: 12px;
        }
    }
}

.ctypes {

    .pricing__btn {

        &:not(:last-child) {
          @extend .mr-sm-20;
        }
    }

    @include media-breakpoint-down(xs) {
      width: 245px;

        .pricing__btn {
          width: 100%;
          border-radius: $border-radius-lg !important;
          text-align: left;
          order: 2;
          margin-bottom: 10px;

            &:not(.active) {
              display: none;
            }

            &.active {
              position: relative;
              order: 1;
              color: $blue;
              border: 1px solid $blue;
              background-color: transparent;

                &:after {
                  content: '';
                  @include element-center(false, 0, 15px, 0, false);
                  @include size(0);
                  border-top: 5px solid $blue;
                  border-left: 4px solid transparent;
                  border-right: 4px solid transparent;
                }
            }
        }

        &.opened .pricing__btn {
          display: block;
        }
    }
}

.ctable {
  color: #444444;
  table-layout: fixed;

    th, td {
      background: $white;
      
        @include media-breakpoint-up(sm) {
          padding: 20px rem(20px);
        }

        @include media-breakpoint-down(xs) {
          padding: 10px 10px;
        }

        &:first-child {
          @extend .text-left;

            @include media-breakpoint-down(xs) {
              width: 152px;
              position: sticky;
              position: -webkit-sticky;
              z-index: 5;
              left: 0;
              box-shadow: inset -1px 0 0 0 rgba(#777, .12);
            }
        }

        &:not(:first-child) {

            @include media-breakpoint-up(md) {
              width: 14%;
            }

            @include media-breakpoint-only(sm) {
              width: 15.6%;
            }

            @include media-breakpoint-down(xs) {
              width: 145px;
            }
        }
    }
  
    th {
      @extend .bg-gray-200;
      position: relative;

        @include media-breakpoint-up(sm) {

            &:before {
              content: '';
              position: absolute;
              right: 0;
              left: 0;
              bottom: 100%;
              height: 7px;
              @include gradient-y(rgba($white, 0), darken(#efefef, 2%));
            }
    
            &:after {
              content: '';
              position: absolute;
              right: 0;
              left: 0;
              top: 100%;
              height: 7px;
              @include gradient-y(darken(#efefef, 2%), rgba($white, 0));
            }
        }

        &:not(:last-child) {
          z-index: 2;
        }

        &:first-child {
          @include border-left-radius(12px);

            @include media-breakpoint-up(sm) {
              box-shadow: 0 0 14px darken(#dadada, 4%);
    
                &:before,
                &:after {
                  display: none;
                }
            }
        }

        &:last-child {
          @include border-right-radius(12px);

            @include media-breakpoint-up(sm) {
              box-shadow: 0 0 14px darken(#dadada, 4%);
    
                &:before,
                &:after {
                  display: none;
                }
            }
        }
    }

    tr {

        &:nth-child(even) {

            td {
              background: #f7fcff;

                &:first-child {
                  @include border-left-radius(12px);
                }

                &:last-child {
                  @include border-right-radius(12px);
                }
            }
        }
    }

    &__icon {
      height: 23px;

        img {
          width: auto;
          max-height: 100%;
        }
    }

    &__title {
      
        @include media-breakpoint-up(md) {
          font-size: $font-size-lg;
        }

        @include media-breakpoint-only(sm) {
          font-size: $font-size-sm;
        }

        @include media-breakpoint-down(xs) {
          font-size: 15px;
        }
    }
}

.cbox {
  box-shadow: 0 10px 26px rgba($black, .07);
  border-radius: 12px;
 border: 1px solid;

    &::before {
      content: '';
      @include element-center(true, 0, false, false, -18px);
      @include size(23px, 121px);
      @include border-top-radius($border-radius-lg);
      @include border-bottom-radius($border-radius-lg);
      @extend .bg-blue;
    }

    @include media-breakpoint-up(sm) {
      font-size: $font-size-lg;
    }

    ul {

        li {
          padding-left: 40px;
          background: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 13'%3e%3cpath fill='#3994ff' d='M6.500,0.007 L13.006,6.506 L6.500,13.002 L-0.007,6.506 L6.500,0.007 Z'/%3e%3c/svg%3e"), "#", "%23") no-repeat 0 6px / 13px 13px;

            &:not(:last-child) {
              margin-bottom: rem(25);
            }
        }
    }
}