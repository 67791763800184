// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant(
    $color,
    $background,
    $border,
    $box-shadow: none,
    $hover-color: darken($color, 15%),
    $hover-background: darken($background, 7.5%),
    $hover-border: darken($border, 10%)
) {
  color: $color;
  @include gradient-bg($background);
  border-color: $border;
  box-shadow: $box-shadow;

    @include hover {
      color: $hover-color;
      @include gradient-bg($hover-background);
      border-color: $hover-border;
    }
}

@mixin button-outline-variant(
  $color,
  $border,
  $box-shadow: none,
  $hover-color: color-yiq($color),
  $hover-background: $color,
  $hover-border: $colors
) {
  color: $color;
  background-color: transparent;
  background-image: none;
  border-color: $border;
  box-shadow: $box-shadow;

  &:hover {
    color: $hover-color;
    background-color: $hover-background;
    border-color: $hover-border;
  }
}

// Button sizes
@mixin button-size($height, $padding-x, $font-size, $line-height, $border-radius) {
  height: $height;
  padding: 0 $padding-x;
  font-size: $font-size;
  line-height: $line-height;
  // Manually declare to provide an override to the browser default
  @if $enable-rounded {
    border-radius: $border-radius;
  } @else {
    border-radius: 0;
  }
}
