.gallery {

    img {
      @extend .rounded;
    }

    &__box {
      font-size: $font-size-base;
      line-height: 1;
      
        &__img {
          padding-bottom: percentage(107 / 195);
          
            img {
              @include element-center(true, 0, -9999px, 0, -9999px);
              min-width: 100%;
              width: auto;
              height: 100%;
            }
        }

        em {
          font-size: 13px;
        }

        &:hover {
          
            a {
              color: #5046ff;
              text-decoration: none;
            }

            .gallery__box__img {
              box-shadow: 0 5px 23px rgba(#5046ff, .25);

                @include media-breakpoint-up(sm) {

                    &:before {
                      content: '';
                      @include element-center(false);
                      @include size(55px);
                      z-index: 2;
                      border-radius: 50%;
                      background: url('#{$images-icons}zoom.svg') no-repeat center / contain;
                      box-shadow: 0 7px 13px rgba(#3a90ff, .35);
                    }
                }
            }
        }
    }

    &__img {

        img {
          @extend .rounded;
        }
    }

    &__prev,
    &__next {
      @include size(110px, 32px);
      font-size: 13px;
      color: #6e6f78;
      background: #f8f8f8;
      font-style: italic;
      @extend .rounded-pill;

        &:hover {
          color: #6e6f78;
          background-color: darken(#f8f8f8, 5%);
        }
    }

    &__txtbx {

        @include media-breakpoint-up(sm) {
          position: relative;
          width: 460px;
          font-size: rem(19px);
          @include border-radius($border-radius-lg);
          box-shadow: 0 10px 26px rgba($black, .06);
          background-color: $white;
          overflow: hidden;

            &:before {
              content: '';
              @include element-center(false, -6px, false, false);
              @include size(263px, 12px);
              background: $blue;
              @include border-radius(12px);
            }
        }

        @include media-breakpoint-down(xs) {
          font-size: 18px;
        }
    }
}