.text-full {

    &:last-child {
      @extend .mb-40;
    }
}

.text-image {

    &:last-child {
      @extend .mb-40;
    }

    @include media-breakpoint-up(sm) {
      background: no-repeat calc(50% - 30rem) center / contain;
    }

    @include media-breakpoint-down(xs) {
      background: no-repeat left 20px / auto 40%;
    }

    &--bg {

        @include media-breakpoint-up(md) {
            img {
              margin-left: -4%;
            }

            .order-sm-2 img {
              margin-left: 4%;
            }
        }
        

        &-1 {
          background-image: url('#{$images-background}map-image-1.svg') !important;
        }

        &-2 {
          background-image: url('#{$images-background}map-image-2.svg') !important;
        }

        &-3 {
          background-image: url('#{$images-background}map-image-3.svg') !important;
        }

        &-4 {
          background-image: url('#{$images-background}map-image-4.svg') !important;
        }
    }

    &--bg-reseverse {

        @include media-breakpoint-up(sm) {
          background-position: calc(50% + 37rem) center;
        }

        @include media-breakpoint-down(xs) {
          background-position: right 20px;
        }
    }

    // figure {
      // margin-bottom: -7%;
    // }

    img {
      
        @include media-breakpoint-up(lg) {
          max-width: none;
        }

        // @include media-breakpoint-between(sm, md) {
        //   max-width: 110%;
        // }
    }
}