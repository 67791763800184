.form {

    &__control {
      display: block;
      width: 100%;
      height: $input-height;
      padding: $input-padding-y $input-padding-x;
      font-family: $input-font-family;
      font-weight: $input-font-weight;
      line-height: $input-line-height;
      color: $input-color;
      background-color: $input-bg;
      background-clip: padding-box;
      border: $input-border-width solid $input-border-color;
      @include border-radius($input-border-radius, 0);
      @include transition($input-transition);

        @include media-breakpoint-down(sm) {
          height: 50px;
        }

        @at-root textarea#{&} {
          @extend .pt-15;
          height: 170px;
        }

        // Placeholder
        &::placeholder {
          color: $input-placeholder-color;
          opacity: 1;
        }

        &:focus {
          border-color: $input-focus-border-color;
          box-shadow: $input-focus-box-shadow;
        }

        .form__group--icon & {
          padding-left: 50px;
        }

        .has-error & {
          color: $input-error-color;
          border-color: $input-error-border-color;
          box-shadow: $input-error-box-shadow;
        }
    }


    &__icon {
      @include size(50px, $input-height);
      left: 0;
      top: 0;
      @extend .position-absolute, .d-flex, .justify-content-center, .align-items-center;

        path {
          @include transition($input-transition);
        }

        .form__group:not(.has-error) .form__control:focus ~ & {
          
            path { fill: $blue; }
        }

        .has-error & {
          path { fill: $red; }
        }
    }

    &__msg {
      @extend .position-absolute;
      top: 0;
      left: 0;
      right: 0;
      padding-left: 50px;
      color: $red;
      font-size: 13px;

        @include media-breakpoint-only(sm) {
          font-size: 11px;
          padding-left: 5px;
        }
    }
}

.wpcf7-not-valid-tip {
  @extend .d-none;
}

.wpcf7 form.invalid .wpcf7-response-output {
  @extend .d-none;
}

.wpcf7 form.sent .wpcf7-response-output {
  border: none;
  @extend .mt-10, .mx-0, .mb-0, .font-weight-bold, .text-green, .text-center;
}