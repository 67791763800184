.social-share {

	&__btn {
		font-size: 15px;
    border: 1px solid transparent;
    @extend .text-gray-800, .font-weight-bold, .px-30, .py-10;
		
		&--facebook {
			border-color: #1778f2;
		}

		&--tweet {
			border-color: #1da1f2;
		}

		&--insta {
			border-color: #2c7fac;
		}

		&:not(:last-child) {
			@extend .mr-10;
		}

		&:hover {
			opacity: .7;
			text-decoration: none !important;
		}
	}
}