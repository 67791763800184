.post__lg {

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    z-index: 2;
    @include gradient-y(transparent,#f3f8fb);
  }
}

  .pi-lg {
  
    &__content{
      left: 0;
      bottom: 0;
      z-index: 10;
      @include media-breakpoint-up(md) {
        padding-right: 200px !important;
      }
      @include media-breakpoint-down(xs) {
        display: flex;
        flex-direction: column!important;
        top: 0;
      }
    }
  
    &__img {

      img {
        height: 100%;
        @include media-breakpoint-down(md) {
          max-width: none !important;
        }
      }
      
    }
  
    &__title {
      line-height: (38/30);
  
      a {
        @extend .text-gunpowder;
      }
    }
  }

.post__sm {

  .pi {
  
    &__category {
      bottom: 10px;
      left: 20px;
    }

    &__title {
      font-size: 20px;
    }
  }
}

.pi {

  &__category {
    font-size: 0;
    
    li {
      @extend .d-inline-block, .mb-10;

      &:not(:last-child) {
        @extend .pr-10;
      }
    }
  }

  &__category-item {
    font-size: 11px;
    padding-top: 5px;
    padding-bottom: 5px;
    @include gradient-x(#513fff, #33aaff);
    @extend .px-15, .font-weight-bold;

    &:hover {
      text-decoration: none;
      @include gradient-x(#33aaff, #513fff);
    }
  }

  &__title {

    a {
      @extend .text-gunpowder;
    }
  }

  &__thumb {
    height: 0;
    padding-bottom: percentage(212px/370px);

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: -9999px;
      right: -9999px;
      margin: auto;
      min-width: 100%;
    }
  }
}
