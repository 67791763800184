.site-header {
  transition: $default-transition;

    .btn {
      border-width: 2px;
    }

    &:not(.is-sticky) {

        @include media-breakpoint-up(md) {
          top: -55px;
        }
    }

    &:not(.is-sticky):not(.site-header--white) {

        .header__btn-white {
          @extend .btn--outline-white;

              &:hover {

                img {
                  filter: brightness(.1);
                }
            }
        }
    }

    &--white,
    &.is-sticky {
      @extend .bg-white;
      border-bottom: 1px solid rgba($black, .09);

        .header__btn-white {
          padding-top: 1px;
          @extend .btn--outline-blue;
          box-shadow: none;

            img {
              filter: brightness(.4);
            }

            &:hover img {

                filter: brightness(1);
            }
        }

        .logo {

            .blue {
              fill: #0c65aa;
            }

            .gray {
              fill: #595850;
            }
        }

        .btn__menu {

            img {
              filter: brightness(.4);
            }
        }
    }

    .header__btn-white {

      img {
        transition: $default-transition;
      }
  }

    .container {
      max-width: 1860px;
    }
}

.topbar {

    a:not(.btn) {
      color: $blue;
    }
}

.btn__search {
  font-size: 0;
  @include size(34px);

    .site-header:not(.is-sticky):not(.site-header--white):not(.search-opened) & {

        img {
          filter: brightness(20);
        }
    }
}

.main-menu {
  line-height: 1;

    @include media-breakpoint-up(sm) {
      font-size: 14px;
    }
    
    .site-header:not(.is-sticky):not(.site-header--white) & {
      color: $white;
    }

    .site-header--white &,
    .is-sticky & {
      color: $gunpowder;
    }

    a {
      @extend .py-5;

        .site-header--white &:hover,
        .is-sticky &:hover {
          color: $blue;
        }
    }

    li {
          
        &:not(:last-child) {
          @extend .mr-sm-50;
        }

        &.current-menu-item > a {
          border-bottom: 2px solid $white;

            .site-header--white &,
            .is-sticky & {
              border-bottom-color: $gunpowder;
            }
        }
    }
}


.searchform {
  @include element-center(true);
  @extend .z-index-3;
  transition: $default-transition;

    .site-header:not(.search-opened) & {
      visibility: hidden;
      opacity: 0;
    }

    input {
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      width: 623px;
      height: 54px;
      border-radius: $border-radius-lg;

        input {
          height: 34px;
        }
    }

    @include media-breakpoint-down(sm) {

        input {
          height: 39px;
        }
    }

    &__close {
      font-size: 0;

        @include media-breakpoint-up(md) {

            &:not(:hover) img {
              opacity: .5;
            }
        }
    }
}