$colors: map-remove(
  $colors,
  // "blue",
  "indigo",
  "purple",
  "pink",
  "red",
  // "orange",
  "yellow",
  // "green",
  "teal",
  "cyan",
  "white",
  "gray",
  "gray-dark"
);

$colors: map-merge((
  'gray-200'  : $gray-200,
  'gray-300'  : $gray-300,
  'gray-500'  : $gray-500,
  'gray-600'  : $gray-600,
  'gray-700'  : $gray-700,
  'gray-800'  : $gray-800,
  'gray-900'  : $gray-900,
  'bayoux'    : $bayoux,
  'gunpowder' : $gunpowder,
  'cadetblue' : $cadetblue,
  'java'      : $java,
  'dodger'    : $dodger,
  'orchid'    : $orchid
), $colors);

$theme-colors: map-remove(
	$theme-colors, 
  "secondary",
  "success",
  "primary",
  "info",
  "warning",
  "danger",
  "light",
  "dark"
);

$enable-caret                              : false;
$enable-prefers-reduced-motion-media-query : false;
$enable-print-styles                       : false;
$enable-validation-icons                   : false;
$enable-deprecation-messages               : false;

$spacers: map-remove(
  $spacers, 1, 2, 3, 4, 5
);

$spacers: map-merge((
  grid-half : $grid-gutter-width / 2,
  grid      : $grid-gutter-width,
  5         : 5px,
  6         : 6px,
  8         : 8px,
  10        : 10px,
  15        : 15px,
  20        : rem(20),
  25        : rem(25),
  30        : rem(30),
  35        : rem(35),
  40        : rem(40),
  50        : rem(50),
  55        : rem(55),
  60        : rem(60),
  70        : rem(70),
  80        : rem(80),
  100        : rem(100)
), $spacers);

$grid-breakpoints: (
  xs : 0,
  sm : 768px,
  md : 992px,
  lg : 1230px,
);

$container-max-widths: (
  sm : 720px,
  md : 960px,
  lg : 1200px
);

$body-bg    : $white;
$body-color : $gray-800;

$rfs-font-size-unit : px;

$link-color            : inherit;
$link-decoration       : none;
$link-hover-color      : $green;
$link-hover-decoration : underline;

$font-family-base : 'Proxima Nova', sans-serif;

$line-height-sm : 1;
$line-height-lg : 1;

$font-size-base : 16px;
$font-size-lg   : 18px;
$font-size-sm   : 14px;

$h1-font-size : rem(60px);
$h2-font-size : rem(50px);
$h3-font-size : rem(40px);
$h4-font-size : rem(30px);
$h5-font-size : rem(25px);
$h6-font-size : $font-size-base;

$font-weight-exbold : 800;

$headings-margin-bottom : 0;
$headings-line-height   : 1.1;
$headings-font-weight   : 700;
$headings-color         : $gunpowder;

$paragraph-margin-bottom : 0;

// Border Radius
$border-radius    : 6px;
$border-radius-lg : 12px;

// Buttons
$btn-font-weight  : 700;
$btn-border-width : 1px;

$btn-height        : 56px;
$btn-padding-x     : 35px;
$btn-font-size     : 18px;
$btn-border-radius : rem(50px);

$btn-height-sm    : 34px;
$btn-padding-x-sm : 30px;
$btn-font-size-sm : 14px;

// Inputs
$input-padding-y          : 0;
$input-padding-x          : 15px;
$input-height             : 55px;
$input-color              : $gunpowder;
$input-border-radius      : $border-radius;
$input-border-color       : #c2c2c2;
$input-transition         : $default-transition;
$input-placeholder-color  : #97979a;
$input-focus-border-color : $blue;
$input-focus-box-shadow   : 0 0 0 5px rgba($blue, .11);
$input-error-color        : $red;
$input-error-border-color : $red;
$input-error-box-shadow   : 0 0 0 5px rgba($red, .11);

// Modal
$modal-content-border-radius: $border-radius;
$modal-dialog-margin: 30px;

$transition-fade:             all .25s linear;