@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova Bold'), local('ProximaNova-Bold'),
      url('#{$font-path}ProximaNova-Bold.woff2') format('woff2'),
      url('#{$font-path}ProximaNova-Bold.woff') format('woff'),
      url('#{$font-path}ProximaNova-Bold.svg#ProximaNova-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova Bold Italic'), local('ProximaNova-BoldIt'),
      url('#{$font-path}ProximaNova-BoldIt.woff2') format('woff2'),
      url('#{$font-path}ProximaNova-BoldIt.woff') format('woff'),
      url('#{$font-path}ProximaNova-BoldIt.svg#ProximaNova-BoldIt') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova Light'), local('ProximaNova-Light'),
      url('#{$font-path}ProximaNova-Light.woff2') format('woff2'),
      url('#{$font-path}ProximaNova-Light.woff') format('woff'),
      url('#{$font-path}ProximaNova-Light.svg#ProximaNova-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova Light Italic'), local('ProximaNova-LightIt'),
      url('#{$font-path}ProximaNova-LightIt.woff2') format('woff2'),
      url('#{$font-path}ProximaNova-LightIt.woff') format('woff'),
      url('#{$font-path}ProximaNova-LightIt.svg#ProximaNova-LightIt') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova Regular'), local('ProximaNova-Regular'),
      url('#{$font-path}ProximaNova-Regular.woff2') format('woff2'),
      url('#{$font-path}ProximaNova-Regular.woff') format('woff'),
      url('#{$font-path}ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova Regular Italic'), local('ProximaNova-RegularIt'),
      url('#{$font-path}ProximaNova-RegularIt.woff2') format('woff2'),
      url('#{$font-path}ProximaNova-RegularIt.woff') format('woff'),
      url('#{$font-path}ProximaNova-RegularIt.svg#ProximaNova-RegularIt') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova Extrabold'), local('ProximaNova-Extrabld'),
      url('#{$font-path}ProximaNova-Extrabld.woff2') format('woff2'),
      url('#{$font-path}ProximaNova-Extrabld.woff') format('woff'),
      url('#{$font-path}ProximaNova-Extrabld.svg#ProximaNova-Extrabld') format('svg');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
