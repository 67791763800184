.create-account {
  background: url('#{$images-background}create-account-bg.svg') no-repeat center / cover;

    &__box {
      background: url('#{$images-background}create-account-box.svg') no-repeat top right;
      box-shadow: 0 10px 50px rgba($black, .12);

        @include media-breakpoint-down(xs) {
          background-size: 60% auto;
        }
    }

    .btn {
      width: 270px;
    }
}

.password {

    &__progress {
      width: 354px;
      background: #e4eff7;

        div {
          flex-grow: 1;
          max-width: 100%;
          height: 5px;

            &:not(:last-child) {
              border-right: 1px solid $white;
            }
        }
    }
}

#pwm-1.active {
  background-color: #ff1d1d;
}

#pwm-2.active {
  background-color: #ff971d;
}

#pwm-3.active {
  background-color: #ffe21d;
}

#pwm-4.active {
  background-color: #ccff1d;
}

#pwm-5.active {
  background-color: #b2e210;
}