.pagination {

    .screen-reader-text {
      @extend .d-none;
    }

    .nav-links {
      @extend .d-flex, .mt-30, .justify-content-center;

        a {
          color: $blue;
          @extend .text-decoration-none;

            &:hover {
              color: $white;
              background: $blue;
            }
        }
    }

    .page-numbers {
      height: 34px;
      min-width: 34px;
      @extend .rounded, .text-center, .font-weight-bold;
      border: 2px solid $blue;
      line-height: 30px;

        &:not(:last-child) {
          @extend .mr-10;
        }
    }

    .current {
      color: $white;
      background: $blue;
    }

    .prev,
    .next {
      @extend .px-15;
    }
}