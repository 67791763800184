.home-header {
  @extend .position-relative, .z-index-2, .overflow-hidden, .text-white, .font-weight-light;
  background: blue no-repeat center bottom; 
  background-size:cover;
  box-shadow: 0 rem(10px) rem(60px) rgba(#2eb9ff, .7);

    @include media-breakpoint-up(sm) {
      min-height: rem(720px);
      padding-top: rem(120px);
      font-size: rem(25px);
      background-image: url('#{$images-background}home-header-bg.jpg');
    }

    @include media-breakpoint-between(sm, md) {

        .container {
          max-width: 100%;
        }
    }

    @include media-breakpoint-down(xs) {
      padding-top: 75px;
      font-size: 20px;
      background-image: url('#{$images-background}home-header-xs-bg.jpg');
    }

    &__slider {
      // overflow: visible;

        .swiper-slide:not(.swiper-slide-active) {
          opacity: 0 !important;
        }

        figure {
          font-size: 0;
        }

        img {
          width: auto;

            @include media-breakpoint-up(lg) {
              height: 530px;
            }

            @include media-breakpoint-only(sm) {
              height: 400px;
            }

            @include media-breakpoint-down(xs) {
              height: 260px;
            }
        }
    }

    .btn {
      
        @include media-breakpoint-up(md) {
          width: 340px;
        }

        // @include media-breakpoint-down(xs) {
        //   width: 240px;
        // }
    }
}