@import '../../node_modules/swiper/swiper.scss';
@import '../../node_modules/swiper/components/effect-fade/effect-fade.scss';
// @import '../../node_modules/swiper/components/lazy/lazy.scss';
@import '../../node_modules/swiper/components/scrollbar/scrollbar.scss';

.swiper-button-prev,
.swiper-button-next {
  top: 0;
  bottom: 0;
  font-size: 0;
  @extend .position-absolute, .my-auto, .z-index-2;

    &.swiper-button-disabled {
      @extend .d-none;
    }

    @include media-breakpoint-up(sm) {
      @include size(50px);
    }

    @include media-breakpoint-down(xs) {
      @include size(34px);
    }
}

.swiper-pagination {
  @extend .d-flex, .justify-content-center;

    button {
      @include size(12px);
      @extend .position-relative;

        &:not(:last-child) {
          @extend .mr-20;
        }

        &:before {
          content: '';
          @include size(6px);
          @include element-center(true);
          background-color: #809faf;
          @extend .rounded-circle;
        }

        &:hover:before {
          background-color: $blue;
        }

        &.swiper-pagination-bullet-active:before {
          @include size(12px);
          background-color: $blue;
          box-shadow: 0 7px 13px rgba($blue, .35);
        }
    }
}

.swiper-pagination-bullets-dynamic {
  @extend .overflow-hidden, .mx-auto;
}