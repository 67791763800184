.customers {
  letter-spacing: 1px;

    .swiper-slide {
      min-width: 1080px;
      cursor: move;

        figure {
          @extend .m-25;
        }

        @media screen and (min-width: 1500px) {
          min-width: 1870px;
        }
    }

    .swiper-container {

        @include media-breakpoint-down(xs) {

            &:before,
            &:after {
              content: '';
              @include element-center(false, 0, false, 0, false);
              width: 60px;
              z-index: 2;
            }

            &:before {
              left: 0;
              @include gradient-x($white, rgba($white, 0));
            }

            &:after {
              right: 0;
              @include gradient-x(rgba($white, 0), $white);
            }
        }
    }
}