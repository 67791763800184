.blog__nav {
  box-shadow: 0 2px 26px rgba(#070757, .09);

  .swiper-wrapper {
    @extend .d-flex, .justify-content-sm-center;
  }

  .swiper-slide {
    width: auto;

    &:not(:last-child) {

      a {
        @extend .pr-20;
      }
    }

    &:not(:first-child) {

      a {
        @extend .pl-20;
      }
    }

    a {
      color: $gunpowder;
      @extend .d-block, .font-weight-bold, .py-25, .py-sm-20;

      &.active,
      &:hover {
        color: $blue;
      }
    }
  }
}