.your-business {

    img {
      @extend .mb-sm-n30;

        @include media-breakpoint-up(lg) {
          max-width: none;
        }

        @include media-breakpoint-between(sm, md) {
          max-width: 150%;
        }
    }
}