.fl {

    &__item {

        @include media-breakpoint-up(sm) {

            &:nth-child(odd) {
              background: url('#{$images-background}fl-left-bg.svg') no-repeat left center;
            }
    
            &:nth-child(even) {
              background: url('#{$images-background}fl-right-bg.svg') no-repeat right center;
            }
        }


        &:not(:last-child) {
          border-bottom: 1px solid #d3d3d3;
        }

        img {

            @include media-breakpoint-up(lg) {
              max-width: none;
            }
        }

        a {
          color: $blue;
          font-weight: $font-weight-bold;
        }

        .entry--content {

            @include media-breakpoint-up(lg) {
              font-size: 20px;
            }
        }

        &__icon {
          height: 31px;

            img {
              width: auto;
              max-height: 100%;
            }
        }

        &__title {
          font-size: 20px;

            @include media-breakpoint-only(sm) {
              font-size: 16px;
            }
        }
    }
}