:not(body).features {

    header {
      color: #7a8d98;
      font-size: rem(25px);
    }

    // figure {
    //  @extend .mb-n70;
    // }

    .text-image {
      overflow: hidden !important;
    }

    a {
      
        &:not(.btn):hover {
          color: $blue;
        }
    }

    &__pin {

        img {
          width: 89px;
        }
    }
}