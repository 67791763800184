.maps-action {
  background: url('#{$images-background}maps-action-bg.jpg') no-repeat center / cover;

    &__tablet {
      background: url('#{$images-img}dark-tablet.svg') no-repeat top center / 100% auto;
      max-width: rem(863px);
      @extend .p-sm-25, .py-20, .px-25;
      width: percentage(279 / 375);
        
        img[src^="data:image/gif;"],
        .lazyloading {
          height: 0;
          padding-bottom: percentage(443 / 813);
        }
    }

    .swiper-slide {
      background: $white;
    }
    
    .swiper-button-prev,
    .swiper-button-next {
      border: 1px solid $blue;
      box-shadow: 0 0 0 4px rgba($blue, .15), 0 17px 44px rgba($blue, .13);

        &:hover {
          background-color: rgba($blue, .3);

            // path {
            //   fill: $white;
            // }
        }
    }

    .swiper-button-prev {
      right: 100%;
    }

    .swiper-button-next {
      left: 100%;
    }
}

