.site-footer {

    &:before {
      content: '';
      @extend .position-absolute, .z-index-2, .mx-auto;
      left: 0;
      right: 0;

      top: rem(-42px);
      @include size(rem(62px), rem(82px));
      background: url('#{$images-icons}footer-logo.svg') no-repeat center / contain;
    }

    a {

        &:not(:hover) {
          color: inherit;
        }
        
        &:hover {
          color: #5cf2ff;
        }
    }

    .col-12 {

        @include media-breakpoint-down(xs) {
          border-bottom: 1px solid rgba(#fdfffd, .12);
        }
    }

    button {
      letter-spacing: 1px;

        &:hover {
          text-decoration: none;
        }

        @include media-breakpoint-down(xs) {
          position: relative;
          padding-right: 20px;

            &:after {
              content: '';
              @include element-center(false, 0, 0, 0, false);
              @include size(12px);
              border-radius: 50%;
            }

            &[aria-expanded="false"]:after {
              background: $blue str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 6'%3e%3cpath fill='#{$white}' d='M5.000,3.999 L4.000,3.999 L4.000,4.999 C4.000,5.552 3.552,5.999 3.000,5.999 C2.448,5.999 2.000,5.552 2.000,4.999 L2.000,3.999 L1.000,3.999 C0.448,3.999 -0.000,3.552 -0.000,2.999 C-0.000,2.448 0.448,1.999 1.000,1.999 L2.000,1.999 L2.000,0.999 C2.000,0.448 2.448,-0.000 3.000,-0.000 C3.552,-0.000 4.000,0.448 4.000,0.999 L4.000,1.999 L5.000,1.999 C5.552,1.999 6.000,2.448 6.000,2.999 C6.000,3.552 5.552,3.999 5.000,3.999 Z'/%3e%3c/svg%3e"), "#", "%23") no-repeat center / 6px 6px;
            }

            &[aria-expanded="true"]:after {
              background: $blue str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 2'%3e%3cpath fill='#{$white}' d='M1.000,-0.000 L5.000,-0.000 C5.552,-0.000 6.000,0.447 6.000,1.000 C6.000,1.552 5.552,2.000 5.000,2.000 L1.000,2.000 C0.448,2.000 -0.000,1.552 -0.000,1.000 C-0.000,0.447 0.448,-0.000 1.000,-0.000 Z'/%3e%3c/svg%3e"), "#", "%23") no-repeat center / 6px 2px;
            }
        }
    }

    .collapse,
    .collapsing {

        @include media-breakpoint-up(sm) {
          height: auto !important;
        }
    }

    .menu,
    .collapse > ul,
    &__menu {

        a {
          @extend .d-inline-block;
        }

        > li {
          @extend .position-relative, .pl-30;
          background: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 9'%3e%3cpath fill='#5cf2ff' d='M4.187,0.130 L8.243,4.186 L4.187,8.241 L0.132,4.186 L4.187,0.130 Z'/%3e%3c/svg%3e"), "#", "%23") no-repeat 0 7px / 9px 9px;
          
            &:not(:last-child) {
              @extend .mb-20;
            }

            > a {
              font-size: 17px;
              @extend .font-weight-bold;
            }


            li {
              @extend .mt-5;

                a {
                  color: #c5c5c5;

                    &:hover {
                      color: #c5c5c5;
                    }
                }
            }
        }

        @include media-breakpoint-down(xs) {
          margin-bottom: 30px;
        }
    }

    &__socials {

        a {

            &:not(:last-child) {
              @extend .mr-sm-30, .mr-50;
            }
        }
    }
}

.copyrights {


    a {
		color: #ffffff;
      @extend .text-white, .mx-15;
    }
}
