.fs {
  background: url('#{$images-background}features-software-bg.svg') no-repeat center 120px / cover;

    @include media-breakpoint-up(lg) {
      padding: rem(120px) 0 rem(70px);

        header {
          font-size: rem(21px);
        }
    }

    @include media-breakpoint-between(sm, md) {
      padding: 100px 0 50px;

        header {
          font-size: rem(21px);
        }
    }

    @include media-breakpoint-down(xs) {
      padding: 80px 0 30px;
    }

    img[src^="data:image"] {
      height: 0;;
      padding-bottom: percentage(300 / 650);
    }
    
    .lazyloading {
      height: 50px;
    }


    &__tools {

        .swiper-slide {
          width: 100% !important;
        }
    }

    &__inner-slider {

        .swiper-slide {
          opacity: 1 !important;
    
            &.swiper-slide-active {
              z-index: 2;
            }
    
            &:not(.swiper-slide-active):not(.swiper-slide-prev) {
              transform: translate3d(0px, 0px, 0px) !important;
            }
        }
    }

    // &__wrapper-tools {
      // height: auto !important;
    // }

    &__wrapper-tools-tabs {
      // min-height: 100%;

        > .swiper-slide {

            &.swiper-slide-prev {
              height: 0;
            }
        }
    }

    &__btn {
      line-height: 1;
      color: $headings-color;
      background-color: $white;
      border: 1px solid #c3c3c3;

        &:hover {
          color: $headings-color;
        }

        @include media-breakpoint-up(sm) {
          border-radius: $border-radius;
        }

        .fs__btns & {

            @include media-breakpoint-up(sm) {
              font-size: $h5-font-size;
              // height: rem(200px);
              
                &:not(:last-child) {
                  margin-bottom: 15px;
                }

                &:before {
                  content: '';
                  @include element-center(false, 0, rem(32px), 0, false);
                  @include size(0);
                  border-top: 8px solid transparent;
                  border-bottom: 8px solid transparent;
                  border-left: 8px solid $gunpowder;
                }
            }

            @include media-breakpoint-down(xs) {
              padding: 10px 4px;
              font-size: 13px;

                &:not(:nth-child(2)):not(:last-child) {
                  border-left: none;
                  border-right: none;
                }

                &:nth-child(2) {
                  @include border-left-radius($border-radius);
                  border-right: none;
                }

                &:last-child {
                  @include border-right-radius($border-radius);
                  border-left: none;
                }
            }
        }

        .fs__cats & {

            &:not(:last-child) {
              margin-bottom: 10px;
            }
        }

        &:not(:hover):not(.active) {

            .fs__btn__cat-icon img {
              filter: brightness(0);
            }
        }
        
        &.active,
        &:hover {
          
            @include media-breakpoint-up(sm) {
              color: $neon;
              border-color: $neon;
              box-shadow: 0 10px 21px rgba(#1d1c35, .25);
            }
        }

        &.active {
          color: $neon;
          font-weight: $font-weight-bold;
          z-index: 2;

            &:after {
              content: '';
              @extend .position-absolute, .m-auto;
              border-top: 1px solid $neon;
              border-right: 1px solid $neon;
              background-color: $white;

                @include media-breakpoint-up(sm) {
                  top: 0;
                  right: -9px;
                  bottom: 0;
                  @include size(16px);
                  transform: rotate(45deg);
                }

                @include media-breakpoint-down(xs) {
                  left: 0;
                  bottom: -7px;
                  right: 0;
                  @include size(12px);
                  transform: rotate(135deg);
                }
            }

            @include media-breakpoint-up(sm) {

                .fs__btns &:before {
                  border-left-color: $neon;
                }
            }

            @include media-breakpoint-down(xs) {
              border-color: $neon;

                &:not(:first-child):not(:last-child) {
                  box-shadow: 0 10px 20px rgba(#1d1c35, .20), 1px 0 0 0 $neon, -1px 0 0 0 $neon;
                }

                &:first-child {
                  box-shadow: 0 10px 20px rgba(#1d1c35, .20), 1px 0 0 0 $neon;
                }

                &:last-child {
                  box-shadow: 0 10px 20px rgba(#1d1c35, .20), -1px 0 0 0 $neon;
                }
            }
        }

        &__icon {
          
            @include media-breakpoint-up(sm) {
              height: 41px;
            }

            @include media-breakpoint-down(xs) {
              height: 31px;
            }

            img {
              width: auto;
              max-height: 100%;
            }
        }

        &__cat-icon {
          @include size(31px);

            img {
              width: auto;
              max-height: 100%;
            }
        }
    }

    &__content {
      box-shadow: 0 rem(10px) rem(44px) rgba(#1d1c35, .25);

        @include media-breakpoint-up(lg) {
          font-size: 17px;
        }

        // @include media-breakpoint-down(xs) {
          
        //     .btn--outline-blue {
        //       width: 100%;
        //     }
        // }

        &--bg {
          background: url('#{$images-background}fs-content-bg.svg') no-repeat right bottom;
        }

        &__title {
          
            @include media-breakpoint-up(md) {
              font-size: 22px;
            }

            @include media-breakpoint-down(sm) {
              font-size: 20px;
            }
        }
    }

    &__cat-box {
      border: 1px solid #c3c3c3;
      line-height: 1.3;
      color: $gunpowder;

        @include media-breakpoint-up(lg) {
          font-size: 15px;
        }

        @include media-breakpoint-up(sm) {
          padding-top: rem(45px);
        }

        @include media-breakpoint-down(xs) {

            strong {
              font-weight: $font-weight-normal;
            }
        }

        img {
          transition: $default-transition;
        }

        &:not(:hover) {

            img {
              opacity: .5;
              filter: brightness(0);
            }
        }


        &__icon {
          
            @include media-breakpoint-up(sm) {
              height: rem(50px);
            }

            @include media-breakpoint-down(xs) {
              @include size(24px);
            }

            img {
              width: auto;
              max-height: 100%;
            }
        }

        &:hover {
          color: $neon;
          border-color: $neon;
          box-shadow: 0 10px 21px rgba(#1d1c35, .25);
        }
    }

    &__back {
      font-size: 0;
      background-color: $neon;
      box-shadow: 0 0 0 4px rgba($neon, .12);

        @include media-breakpoint-up(sm) {
          @include size(40px);
        }

        @include media-breakpoint-down(xs) {
          @include size(35px);
        }
    }

    &__back-icon {

        @include media-breakpoint-up(sm) {
          @include size(31px);
        }

        @include media-breakpoint-down(xs) {
          @include size(18px);
        }

        img {
          width: auto;
          max-height: 100%;
        }
    }

    &__back-text {
      line-height: 1;

        @include media-breakpoint-up(sm) {
          font-size: $font-size-lg;
        }
    }
}