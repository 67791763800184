:not(body).testimonials {
  background: url('#{$images-background}group-bg-1.svg') no-repeat center / cover;

    @include media-breakpoint-down(xs) {
      
        .btn--sm {
          width: 100%;
          height: 50px;
          font-size: 18px;
        }
    }

    .container {
      max-width: 1250px;
    }

    header {
      background: url('#{$images-icons}t-heart.svg') no-repeat center / contain;
    }

    &__box {

        &--shadow {
          box-shadow: 0 rem(10px) rem(50px) rgba($black, .12);
        }

        &__avatar {
          @include size(48px);
          box-shadow: 0 rem(11px) rem(28px) rgba(#a2b7c9, .57);
        }

        &__logo {
          max-width: 110px;
        }
    }

    &__item {
      
        @include media-breakpoint-up(sm) {
          font-size: rem(28px);
        }

        @include media-breakpoint-down(xs) {
          font-size: 20px;
        }
    }
    
    .swiper-button-prev,
    .swiper-button-next {

        @include media-breakpoint-up(sm) {
          border: 1px solid $blue;
          box-shadow: 0 0 0 4px rgba($blue, .15), 0 17px 44px rgba($blue, .13);

              &:hover {
                background-color: rgba($blue, .3);
        
                  // path {
                  //   fill: $white;
                  // }
              }
        }

        @include media-breakpoint-down(xs) {
          background-color: #dbe4ea;
          border-radius: 5px;
            
            svg {
              @include size(13px, 9px);
            }
        }
    }

    .swiper-button-prev {
      
        @include media-breakpoint-up(sm) {
          right: 100%;
        }
      
        @include media-breakpoint-down(xs) {
          left: -5px;
        }
    }

    .swiper-button-next {

        @include media-breakpoint-up(sm) {
          left: 100%;
        }
      
        @include media-breakpoint-down(xs) {
          right: -5px;
        }
    }

    .swiper-pagination {
      @extend .mt-10;
    }
}


.swiper-slide-active .testimonials__box {
  box-shadow: 0 rem(10px) rem(50px) rgba($black, .12);
}